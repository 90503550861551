import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/info',
    name: '학원소개',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "info" */ '../views/info.vue')
  },
  {
    path: '/location',
    name: '위치안내',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "info" */ '../views/location.vue')
  },
  {
    path: '/programs',
    name: '프로그램소개',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "programs" */ '../views/programs.vue')
  },
  {
    path: '/brand',
    name: '브랜드',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "programs" */ '../views/brand.vue')
  },
  {
    path: '/study',
    name: '학습구성',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "programs" */ '../views/study.vue')
  },
  {
    path: '/curri',
    name: '커리큘럼',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "programs" */ '../views/curri.vue')
  },

  {
    path: '/notice',
    name: '공지사항',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/notice.vue')
  },
  {
    path: '/campus_notice',
    name: '캠퍼스 공지사항',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/campus_notice.vue')
  },
  {
    path: '/gallery',
    name: '캠퍼스 갤러리',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/gallery.vue')
  },

  {
    path: '/faq',
    name: 'faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/faq.vue')
  },
  {
    path: '/test',
    name: '레벨테스트',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/test.vue')
  },
  {
    path: '/payment',
    name: '수강신청',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/payment.vue')
  },

  {
    path: '/articles/:article',
    name: 'article reader',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "articles" */ '../views/Article.vue')
  },

  {
    path: '/leveltest/:level',
    name: '레벨테스트 시험',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "articles" */ '../views/leveltest.vue')
  },
  {
    path: '/test/:idx/intro',
    name: '레벨테스트_2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/test.vue')
  },
  {
    path: '/test/:idx/:level',
    name: '레벨테스트 시험',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "articles" */ '../views/leveltest.vue')
  },
  {
    path: '/cpt/:idx/:level',
    name: 'ALS레벨테스트 시험',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "articles" */ '../views/PlacementTest.vue')
  },
  {
    path: '/menu',
    name: '앱뷰',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "notice" */ '../views/menu.vue')
  },
  {
    path: '/event',
    name: '프로그램소개',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "event" */ '../views/event.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
