import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

createApp(App).use(router).mount("#app");

if (localStorage.getItem("no_inst_popup") < new Date()) {
  //document.getElementById('popup_inst').style.display = '';
  fetch("https://api.mypathkr.com/popups/pchome")
    .then((response) => response.json())
    .then((data) => {
      if (data.result.length) {
        var base = document.getElementById("listings").innerHTML;
        var resHtml = "";
        data.result.forEach((element, i) => {
          resHtml += base
            .replace('id="baseListing"', "")
            .replace("{link}", element.link_href)
            .replace("{target}", element.target == "_blank" ? "_blank" : "")
            .replace("{imageurl}", element.image_url)
            .replace("{width}", element.image_width)
            .replace("{height}", element.image_height);
        });
        document.getElementById("listings").innerHTML = resHtml;
        $("#exampleModal").modal();

        $(".bxslider").bxSlider({
          auto: true,
          stopAutoOnClick: true,
          pager: true,
          touchEnabled: false,
          slideWidth: 600,
        });
      }
    });
}
function no_watch_today() {
  document.getElementById("popup_inst").style.display = "none";
  localStorage.setItem("no_inst_popup", +new Date() + 86400000);
}
function ccc() {
  document.getElementById("popup_inst").style.display = "none";
  localStorage.setItem("no_inst_popup", 1);
}

document.getElementById("popctrl").addEventListener("click", (e) => {
  e.preventDefault();
  ccc();
});
function gourl() {
  location.href =
    "https://www.childu.co.kr/campus/sub/Contact%20Us/schedule.asp";
}
